import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const GuidePage = () => (
	<Layout
		meta={{
			title: "Backup Plan",
			description:
				"Accento 2021 takes place on Hopin - here's what to do if there's trouble.",
			path: "/",
		}}
		wide
	>
		<Snippet id="backup-all-good" />

		{/* <Snippet id="backup-trouble" /> */}
		{/* <Snippet id="backup-hopin" /> */}
		{/* <Snippet id="backup-youtube" /> */}
	</Layout>
)

export default GuidePage
